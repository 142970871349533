import isClient from "@/utils/isClient";

async function initMocks() {
  if (!isClient()) {
    const { server } = await import("./server");
    server.listen();
  } else {
    const { worker } = await import("./browser");
    worker.start({
      serviceWorker: {
        // Specify the worker script URL relative to the _root_.
        url: "/research/mockServiceWorker.js",
        options: {
          // Override the scope to the root ("/").
          // By default, the worker is scoped to its location on your server,
          // which in this case would be "/prefix".
          scope: "/",
        },
      },
    });
  }
}

initMocks();

export {};
