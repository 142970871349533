import { GenericSport } from "@/graphql/gql/graphql";
import { useMemo } from "react";
import Header from "../header/HeaderContainer";
import Footer from "../footer/Footer";
import styles from "./layout.module.scss";
import MobileSubNavigation from "../header/MobileSubNavigation/MobileSubNavigation";
import SportsbookPromo from "../sportsbookPromo/SportsbookPromo";

export type LayoutProps = {
  children: JSX.Element;
  sport?: Partial<GenericSport> | string;
  sportName?: string;
  sportSlug?: string;
  preview?: boolean;
  noStyle?: boolean;
};

export function HomeLayout({ children, ...pageProps }: LayoutProps) {
  const sport = useMemo(() => {
    if (pageProps.sportName) return pageProps.sportName;
    return typeof pageProps.sport === "string"
      ? pageProps.sport
      : pageProps.sport?.name;
  }, [pageProps.sport, pageProps.sportName]);
  return (
    <>
      <Header sport={sport} sportSlug={pageProps.sportSlug} />
      <SportsbookPromo />
      <div>{children}</div>
      <Footer />
    </>
  );
}

export default function Layout({
  children,
  noStyle,
  ...pageProps
}: Readonly<LayoutProps>) {
  const isPreview = pageProps.preview;
  const sport = useMemo(() => {
    if (pageProps.sportName) return pageProps.sportName;
    return typeof pageProps.sport === "string"
      ? pageProps.sport
      : pageProps.sport?.name;
  }, [pageProps.sport, pageProps.sportName]);

  // do not render headers and footer if preview screen
  if (isPreview) {
    return <div className={styles.mainContainerPreview}>{children}</div>;
  }

  return (
    <>
      <Header sport={sport} sportSlug={pageProps.sportSlug} />
      <MobileSubNavigation sport={sport} sportSlug={pageProps.sportSlug} />
      {noStyle ? (
        <>
          <SportsbookPromo />
          {children}
        </>
      ) : (
        <main className={styles.mainContainer}>
          <SportsbookPromo />
          {children}
        </main>
      )}
      <Footer />
    </>
  );
}
