import { isMobile } from "react-device-detect";
import { Button } from "@fanduel/formation-react-components";
import { ArrowsChevronRight } from "@fanduel/formation-react-icons";
import { Loader } from "@fanduel/og-fe-library";
import Link from "next/link";
import useSiteSettings from "@/utils/hooks/useSiteSettings";
import { useAmplitude } from "@/utils/hooks/useAmplitude";
import {
  SPORTSBOOK_PROMO_BANNER_CLICKED,
  SPORTSBOOK_APP_DOWNLOAD_CLICKED,
} from "@/constants/amplitude";
import { SbkPromoBanner } from "@/graphql/gql/graphql";
import Styles from "./SportsbookPromo.module.scss";
import ChevronsTexture from "../svg/promoBanner/ChevronsTexture";
import ChevronsTextureMobile from "../svg/promoBanner/ChevronsTextureMobile";
import SportsBookLogo from "../svg/promoBanner/SportsBookLogo";
import PlayerImg from "../svg/promoBanner/PlayerImg";
import { useEffect, useState } from "react";

const SportsbookPromo = () => {
  const { data, isLoading, isError } = useSiteSettings();
  const { logAmplitudeEvent } = useAmplitude();

  const [linkText, setLinkText] = useState("");
  const [linkUrl, setLinkUrl] = useState("");

  // useEffect needed to prevent hydration errors on the client
  useEffect(() => {
    if (data?.getSiteSettings?.sbkPromoBanner) {
      const { desktopButtonText, desktopLink, mobileButtonText, mobileLink } =
        data.getSiteSettings.sbkPromoBanner as SbkPromoBanner;

      if (isMobile) {
        setLinkText(mobileButtonText ?? "");
        setLinkUrl(mobileLink ?? "");
      } else {
        setLinkText(desktopButtonText ?? "");
        setLinkUrl(desktopLink ?? "");
      }
    }
  }, [data]);

  if (data?.getSiteSettings?.sbkPromoBanner?.hidePromoBanner) {
    return null;
  }

  if (isLoading) {
    return <Loader className={Styles.loading} />;
  }

  if (isError || !data?.getSiteSettings?.sbkPromoBanner) return null;

  const ampProps = {
    "Link Text": linkText.toLowerCase().replace(/\s+/g, "_"),
    "Link URL": linkUrl,
    Module: "header_ribbon",
  };

  const handlePromoClick = () => {
    const eventType = isMobile
      ? SPORTSBOOK_APP_DOWNLOAD_CLICKED
      : SPORTSBOOK_PROMO_BANNER_CLICKED;
    logAmplitudeEvent(eventType, ampProps);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.left}>
        <h1>
          START YOUR OWN&nbsp;
          <span className={Styles.winning}>WINNING</span>
          &nbsp;STREAK
        </h1>
        <div className={Styles.playerImg}>
          <PlayerImg />
        </div>
      </div>
      <div className={Styles.right}>
        <div className={Styles.desktopLogo}>
          <SportsBookLogo />
        </div>
        <Link href={linkUrl}>
          <Button
            label={linkText}
            size="small"
            iconRight={<ArrowsChevronRight />}
            onClick={handlePromoClick}
          />
        </Link>
      </div>
      <div className={Styles.chevrons}>
        <div className={Styles.chevronsDesktop}>
          <ChevronsTexture />
        </div>
        <div className={Styles.chevronsMobile}>
          <ChevronsTextureMobile />
        </div>
      </div>
    </div>
  );
};

export default SportsbookPromo;
