import { graphql } from "../gql";

export default graphql(`
  query getSingleAuthorQuery($slug: String!) {
    getSingleAuthor(slug: $slug) {
      authoredSubCategories {
        title
        slug
        sport {
          name
          slug
        }
      }
      authoredSports {
        name
        slug
      }
      name
      socialMedia {
        accountName
        type
        url
      }
      favoriteSports {
        name
        slug
      }
      featuredBet {
        eventName
        description
        article {
          sport {
            name
          }
          title
          slug
        }
        market {
          type
          props {
            marketId
            marketName
            marketStatus
            marketType
            inPlay
            sgmMarket
            externalMarketId
            selections {
              selectionId
              odds
              handicap
              formattedHandicap
              runnerName
              runnerStatus
              url
              sortPriority
              result {
                type
              }
            }
          }
        }
      }
      id
      thumbnailUrl
      profileImageUrl
      bio
      hideContent
    }
  }
`);
