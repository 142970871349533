import { graphql } from "../gql";

const getSubCategoriesQuery = graphql(`
  query GetSubCategoriesQuery {
    getSubCategories {
      slug
      sport {
        slug
      }
    }
  }
`);

export default getSubCategoriesQuery;
