const getConfig = () => ({
  serverRuntimeConfig: {
    PREVIEW_SECRET: process.env.PREVIEW_SECRET || "",
    JW_PLAYER_KEY: process.env.JW_PLAYER_KEY || "",
  },
  publicRuntimeConfig: {
    AMP_ENV: process.env.NEXT_PUBLIC_AMP_ENV || "",
    SEARCH_ENGINES: process.env.NEXT_PUBLIC_SEARCH_ENGINES || "",
    DD_APP_ID: process.env.NEXT_PUBLIC_DD_APP_ID || "",
    DD_APP_TOKEN: process.env.NEXT_PUBLIC_DD_APP_TOKEN || "",
    DD_TRACE_ENABLED: process.env.NEXT_PUBLIC_DD_TRACE_ENABLED || "",
    DD_ENV: process.env.NEXT_PUBLIC_DD_ENV || "",
    DD_SERVICE: process.env.NEXT_PUBLIC_DD_SERVICE || "",
    DD_VERSION: process.env.NEXT_PUBLIC_DD_VERSION || "",
    ORCHESTRATION_ENDPOINT:
      process.env.NEXT_PUBLIC_ORCHESTRATION_ENDPOINT || "",
    API_MOCKING: process.env.NEXT_PUBLIC_API_MOCKING || "",
  },
});

export default getConfig;
