import { graphql } from '../gql';

const getHomepageQuery = graphql(`
query GetHomepage($isPreview: Boolean) {
  getHomepage(isPreview: $isPreview) {
    template
    tools {
      description
      title
      url
      newWindow
    }
    selectedSports {
      sport {
        name
        slug
        hasPlayerUpdates
        logo {
          url 
        }
      }
      tool {
        description
        newWindow
        title
        url
      }
      featuredArticles {
        description
        firstPublishedAt
        id
        slug
        subHeading
        title
        author {
          id
          name
          slug
        }
        hero {
          imageUrl {
            url
          }
        }
        seo {
          description
          noIndex
          canonicalUrl
          title
        }
        thumbnailImageUrl {
          url
        }
      }
    }
    articles {
      description
      firstPublishedAt
      id
      slug
      subHeading
      title
      author {
        id
        name
        slug
      }
      hero {
        imageUrl {
          url
        }
      }
      seo {
        description
        noIndex
        canonicalUrl
        title
      }
      thumbnailImageUrl {
        url
      }
      sport {
        name
        slug
        logo {
          url
        }
      } 
    }
  }
}
`);

export default getHomepageQuery;
