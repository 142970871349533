/* eslint-disable @next/next/no-img-element */
import styles from "./Footer.module.scss";

export const ResponsibleGaming = () => (
  <div className={styles.rgText}>
    Gambling Problem? Call{" "}
    <img
      src="https://cdn.sanity.io/images/pbwem9y5/fanduel_marketing_production/06b870aaf8c5e6b4ea9b3db90527d34e9144ad34-87x10.png?fit=max&w=600&h=600"
      alt="One Eight Hundred G A M B L E R"
      data-nosnippet
    />{" "}
    or visit{" "}
    <a href="https://RG-help.com" data-nosnippet>
      RG-help.com
    </a>
    . Call{" "}
    <img
      src="https://cdn.sanity.io/images/pbwem9y5/fanduel_marketing_production/a60b10d5292d0694bca33412097898ceb46beaa2-81x10.png?fit=max&w=600&h=600"
      alt="Eight Eight Eight Seven Eight Nine Seven Seven Seven Seven"
      data-nosnippet
    />{" "}
    or visit{" "}
    <a href="https://ccpg.org/chat" data-nosnippet>
      ccpg.org/chat
    </a>{" "}
    (CT). Hope is here.
    <a href="https://GamblingHelpLineMA.org" data-nosnippet>
      GamblingHelpLineMA.org
    </a>{" "}
    or call{" "}
    <img
      src="https://cdn.sanity.io/images/pbwem9y5/fanduel_marketing_production/b339733068af2b92ac9fc0a7fc726d0e207b686f-83x12.png?fit=max&w=600&h=600"
      alt="Eight Hundred Three Two Seven Five Zero Five Zero"
      data-nosnippet
      style={{ flex: 1 }}
    />{" "}
    for 24/7 support (MA). Visit{" "}
    <a href="https://www.mdgamblinghelp.org" data-nosnippet>
      www.mdgamblinghelp.org
    </a>{" "}
    (MD). Call{" "}
    <img
      src="https://cdn.sanity.io/images/pbwem9y5/fanduel_marketing_production/f4f96326463e6e46e182b387887c45136c88111b-89x10.png?fit=max&w=1200&h=1200"
      alt="Eight Seven Seven Eight H O P E N Y"
      data-nosnippet
    />{" "}
    or text <span data-nosnippet>HOPENY (467369)</span> (NY). 21+ (18+ D.C.) and
    present in select states (for KS, in affiliation with Kansas Star Casino).
  </div>
);
