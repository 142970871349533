import { graphql } from "../gql";

const getArticlesQuery = graphql(`
  query GetArticles($filter: ArticleSearchInput!) {
    getArticles(filter: $filter) {
      pageInfo {
        hasNextPage
        cursor
      }
      articles {
        entity {
          firstPublishedAt
          publishedDate
          slug
          description
          id
          title
          seo {
            description
          }
          sport {
            name
            slug
            logo {
              url
            }
          }
          thumbnailImageUrl {
            url
          }
          hero {
            imageUrl {
              url
            }
          }
          author {
            name
            id
            slug
            socialMedia {
              type
              url
            }
          }
        }
      }
    }
  }
`);

export default getArticlesQuery;
